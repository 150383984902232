import React from 'react';
import './About.css';
import aboutImg from '../../assets/About/img (12).jpg'; // Update with your image path
import aboutIcon1 from '../../assets/img/about-icon-1.png';
import aboutIcon2 from '../../assets/img/about-icon-2.png';
import aboutIcon3 from '../../assets/img/about-icon-3.png';
import {FaMinus} from "react-icons/fa";

const AboutSection = ({ selectedLanguage }) => {
    return (
        <main className='main'>
            <section className="section about" id="about">
                <h1 className="section-title">
                    {selectedLanguage === 'en' ? 'About Us'
                        : selectedLanguage === 'tr' ? 'Hakkımızda'
                            : selectedLanguage === 'fa' ? 'درباره ما'
                                : null
                    }
                </h1>

                <div className='about-container container'>
                    <div className="about-image-container">
                        <img className='about-image' src={aboutImg} alt="About Us"/>
                    </div>

                    <div className="about-content">
                        <div className='about-info'>
                            <h3 className="section-subtitle" dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                                <FaMinus className='section-subtitle-icon'/>
                                {selectedLanguage === 'en' ? 'About Our Transportation Services'
                                    : selectedLanguage === 'tr' ? 'Hakkımızda'
                                        : null
                                }
                            </h3>
                            <p dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                                {selectedLanguage === 'en' ? 'BAKİ EREN INTERNATIONAL TRANSPORTATION meticulously provides transportation services for ice cream, fresh fruits and vegetables, frozen food, pharmaceuticals, electronics, and all types of goods.'
                                    : selectedLanguage === 'tr' ? 'BAKİ EREN ULUSLARARASI NAKLİYAT, dondurma, yaş sebze-meyve, donmuş gıda, ilaç, elektronik eşya ve her türlü taşımacılık hizmetini titiz bir şekilde yapmaktadır.'
                                        : null
                                }
                            </p>
                            <p dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                                {selectedLanguage === 'en' ? 'We especially ensure reliable and fast transportation to Balkan countries, Middle Eastern countries, and Turkic Republics.'
                                    : selectedLanguage === 'tr' ? 'Özellikle Balkan Ülkelerine, Orta Doğu Ülkelerine ve Türk Cumhuriyetlerine güvenilir ve seri şekilde tüm taşımacılık işlemlerini gerçekleştirmektedir.'
                                        : null
                                }
                            </p>

                            <div className="about-card-container">
                                <div className="about-card">
                                    <img src={aboutIcon1} className='about-card-img' alt="Fresh Ingredients"/>
                                    <h3 className="about-card-title">
                                        {selectedLanguage === 'en' ? 'Reliable Transportation'
                                            : selectedLanguage === 'tr' ? 'Güvenilir Taşımacılık'
                                                : null
                                        }
                                    </h3>
                                </div>
                                <div className="about-card">
                                    <img src={aboutIcon2} className='about-card-img' alt="Cozy Ambiance"/>
                                    <h3 className="about-card-title">
                                        {selectedLanguage === 'en' ? 'Speedy Delivery'
                                            : selectedLanguage === 'tr' ? 'Hızlı Teslimat'
                                                : null
                                        }
                                    </h3>
                                </div>
                                <div className="about-card">
                                    <img src={aboutIcon3} className='about-card-img' alt="Exceptional Service"/>
                                    <h3 className="about-card-title">
                                        {selectedLanguage === 'en' ? 'Global Reach'
                                            : selectedLanguage === 'tr' ? 'Global Erişim'
                                                : null
                                        }
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AboutSection;
