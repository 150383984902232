import React, { useState } from 'react';
import './HomeFigure.css';
import {FaMinus} from "react-icons/fa";

const Location = ({ selectedLanguage }) => {

    return (
        <main className='' >
            <section className='home-figure-section section'>
                <h1 className="section-title">
                    {selectedLanguage === 'en' ? 'Our Vision for the Future'
                        : selectedLanguage === 'tr' ? 'Geleceğe Yönelik Vizyonumuz'
                            : null
                    }
                </h1>
                <div className='home-figure-container container'>
                    <article className='home-fiqure-card'>
                        <h3 className='section-subtitle'>
                            <FaMinus className='section-subtitle-icon'/>
                            {selectedLanguage === 'en' ? 'Vision'
                                : selectedLanguage === 'tr' ? 'Vizyon '
                                    : null
                            }
                        </h3>
                        <p className='home-figure-paragraph'>
                            {selectedLanguage === 'en' ? 'To be recognized as a leading company focused on technological innovation and sustainability in the global logistics sector. We aim to shape the future of the sector with the innovative solutions we offer to our customers and make global trade more efficient and connected.'
                                : selectedLanguage === 'tr' ? 'Global lojistik sektöründe teknolojik inovasyon ve sürdürülebilirlik odaklı lider bir firma olarak tanınmak. Müşterilerimize sunduğumuz yenilikçi çözümlerle sektörün geleceğine yön vererek, dünya ticaretini daha verimli ve bağlantılı hale getirmeyi hedefliyoruz. '
                                    : null
                            }
                        </p>
                    </article> 
                    
                    <article className='home-fiqure-card'>
                        <h3 className='section-subtitle'>
                            <FaMinus className='section-subtitle-icon'/>
                            {selectedLanguage === 'en' ? 'Mission'
                                : selectedLanguage === 'tr' ? 'Misyon  '
                                    : null
                            }
                        </h3>
                        <p className='home-figure-paragraph'>
                            {selectedLanguage === 'en' ? 'We offer safe, fast and economical logistics solutions to meet our customers\' international shipping needs. We are committed to being a reliable partner of global trade by prioritizing transparency and excellence in all processes.'
                                : selectedLanguage === 'tr' ? 'Müşterilerimizin uluslararası nakliye ihtiyaçlarını karşılamak için güvenli, hızlı ve ekonomik lojistik çözümler sunuyoruz. Tüm süreçlerde şeffaflık ve mükemmelliği ön planda tutarak, global ticaretin güvenilir ortağı olmayı taahhüt ediyoruz.'
                                    : null
                            }
                        </p>
                    </article>
                </div>
            </section>
        </main>
    );
};

export default Location;
