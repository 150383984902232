import React, { useState } from 'react';
import './Location.css';
import {FaMinus} from "react-icons/fa";
import Azerbaijan from "../../assets/About/Flag_of_Azerbaijan.png";
import Georgia from "../../assets/About/Flag_of_Georgia.png";
import Iraq from "../../assets/About/Flag_of_Iraq.png";
import Iran from "../../assets/About/Flag_of_Iran.png";
import Bulgaria from "../../assets/About/Flag_of_Bulgaria.png";
import Serbia from "../../assets/About/Flag_of_Serbia.png";
import Albania from "../../assets/About/Flag_of_Albania.png";
import Bosna_Hersek from "../../assets/About/Flag_of_Bosnia and Herzegovina.png";
import mapVector from '../../assets/map-vector.png';

const Location = ({ selectedLanguage ,navigate}) => {
    
    return (
        <main className='main location-main'>
            <div className='hero-section'
                 style={{
                     backgroundImage: `url(${mapVector})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'contain',
                     backgroundPosition: 'center center'
                 }}
            >
                <div className='hero-data'>
                    <h1>
                        {selectedLanguage === 'en' ? 'Countries We Work In'
                            : selectedLanguage === 'tr' ? 'Çalıştığımız Ülkeler '
                                : null
                        }
                    </h1>
                </div>
            </div>
            <div className='location-container container'>

                    <div className='about-box-content'>
                        <h3 className='section-subtitle'>
                            <FaMinus className='section-subtitle-icon'/>
                            {selectedLanguage === 'en' ? 'Asian Countries'
                                : selectedLanguage === 'tr' ? 'Asya ülkeleri '
                                    : null
                            }
                        </h3>
                        <article className='about-box-list'>
                            <div className="about-box">
                                <img src={Azerbaijan} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Azerbaijan'
                                        : selectedLanguage === 'tr' ? 'Azerbaycan'
                                            : null
                                    }
                                </p>
                            </div>

                            <div className="about-box">
                                <img src={Georgia} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Georgia'
                                        : selectedLanguage === 'tr' ? 'Gürcistan'
                                            : null
                                    }
                                </p>
                            </div>

                            <div className="about-box">
                                <img src={Iraq} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Iraq'
                                        : selectedLanguage === 'tr' ? 'Irak'
                                            : null
                                    }
                                </p>
                            </div>

                            <div className="about-box">
                                <img src={Iran} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Iran'
                                        : selectedLanguage === 'tr' ? 'İran'
                                            : null
                                    }
                                </p>
                            </div>
                        </article>
                    </div>

                    <div className='about-box-content'>
                        <h3 className='section-subtitle'>
                            <FaMinus className='section-subtitle-icon'/>
                            {selectedLanguage === 'en' ? 'Balkan ülkeleri'
                                : selectedLanguage === 'tr' ? 'Balkan Countries '
                                    : null
                            }
                        </h3>
                        <article className='about-box-list'>
                            <div className="about-box">
                                <img src={Bulgaria} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Bulgaria'
                                        : selectedLanguage === 'tr' ? 'Bulgaristan '
                                            : null
                                    }
                                </p>
                            </div>

                            <div className="about-box">
                                <img src={Serbia} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Serbia'
                                        : selectedLanguage === 'tr' ? 'Sırbistan '
                                            : null
                                    }
                                </p>
                            </div>

                            <div className="about-box">
                                <img src={Albania} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Albania'
                                        : selectedLanguage === 'tr' ? 'Arnavutluk'
                                            : null
                                    }
                                </p>
                            </div>

                            <div className="about-box">
                                <img src={Bosna_Hersek} className='about-box-img' alt="Exceptional Service"/>
                                <p className="about-box-paragraph">
                                    {selectedLanguage === 'en' ? 'Bosnia-Herzegovina'
                                        : selectedLanguage === 'tr' ? 'Bosna-Hersek'
                                            : null
                                    }
                                </p>
                            </div>
                        </article>
                    </div>

                </div>
        </main>
    );
};

export default Location;
