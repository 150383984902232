import React, { useState, useRef, useEffect } from 'react';
import './Header.css';
import { NavLink } from "react-router-dom";
import Logo from '../../assets/logo.png';
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";

// FLAGS
import USA from '../../assets/img/flags/USA-Flag.png';
import TR from '../../assets/img/flags/TR-Flag.png';


const Header = ({ handleLanguageChange, selectedLanguage }) => {
    const [headerTopVisible, setHeaderTopVisible] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const headerRef = useRef(null);

    const toggleMenu = () => setMenuOpen(prevState => !prevState);

    const closeMenu = (e) => {
        if (headerRef.current && !headerRef.current.contains(e.target)) {
            setMenuOpen(false);
            setDropdownOpen(false);
        }
    };

    const handleLinkClick = () => {
        setMenuOpen(false);
        setDropdownOpen(false);
    };

    const handleDropdownClick = () => {
        setDropdownOpen(false);
        setMenuOpen(false);
    };

    const handleScroll = () => {
        if (window.scrollY > 150) {
            setHeaderTopVisible(false);
        } else {
            setHeaderTopVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('click', closeMenu);
        return () => document.removeEventListener('click', closeMenu);
    }, []);
    return (
        <header className="header" ref={headerRef}>
            <div className={`header-top container ${headerTopVisible ? '' : 'hidden'}`}>
                <div className="language-selector">
                    <label htmlFor="language">
                        {selectedLanguage === 'en' ? 'Language' : selectedLanguage === 'tr' ? 'Dil' : 'زبان'}
                    </label>
                    <select
                        id="language"
                        value={selectedLanguage}
                        onChange={(e) => handleLanguageChange(e.target.value)}
                    >
                        <option className='language-option' value="en">English</option>
                        <option className='language-option' value="tr">Turkish</option>
                    </select>
                    <span className='header-top-flag'>
                                {selectedLanguage === 'en' ? (
                                    <img src={USA} className='flag-img' alt='USA flag'/>
                                ) : selectedLanguage === 'tr' ? (
                                    <img src={TR} className='flag-img' alt='Turkish flag'/>
                                ) : null}

                    </span>
                </div>

                <p className='header-top-address'>
                    <IoLocationSharp className='ht_address-icon'/>
                    Nusaybin Sanayi Sitesi E Blok Nusaybin/ Mardin
                </p>
            </div>

            <nav className="nav container">
                <div className="nav-logo-container" >
                    <img src={Logo} alt="logo" className="nav-logo-img"/>
                </div>

                <div
                    className="nav__toggle"
                    onClick={toggleMenu}
                    aria-expanded={menuOpen}
                    aria-controls="navMenu"
                    role="button"
                    tabIndex="0"
                >
                    <FaBars className="nav__burger"/>
                </div>

                <div id="navMenu" className={`nav__menu ${menuOpen ? 'show-menu' : ''}`}>
                    <ul className="nav__list">
                        <IoClose onClick={toggleMenu} className="nav__close"/>
                        <li>
                            <NavLink
                                exact
                                to='/'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                {selectedLanguage === 'en' ? 'Home'
                                    : selectedLanguage === 'tr' ? 'Ana Sayfa'
                                        : null
                                }
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to='/about'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleDropdownClick}
                            >
                                {selectedLanguage === 'en' ? 'About Us'
                                    : selectedLanguage === 'tr' ? 'Hakkımızda'
                                        : null
                                }
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to='/services'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                {selectedLanguage === 'en' ? 'Our Services'
                                    : selectedLanguage === 'tr' ? 'Hizmetlerimiz'
                                        : null
                                }
                            </NavLink>
                        </li>
                        
                        <li>
                            <NavLink
                                to='/location'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                {selectedLanguage === 'en' ? 'Location'
                                    : selectedLanguage === 'tr' ? 'Lokasyon'
                                        : null
                                }
                            </NavLink>
                        </li>
                        
                        <li>
                            <NavLink
                                to='/contact'
                                className='nav__link'
                                activeClassName='active'
                                onClick={handleLinkClick}
                            >
                                {selectedLanguage === 'en' ? 'Contact'
                                    : selectedLanguage === 'tr' ? 'İletişim'
                                        : null
                                }
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
