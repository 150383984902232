import React from 'react';
import './Footer.css'


const Footer = ({ selectedLanguage,navigate }) => {

    
    return (
        <footer className="footer">
            <p className="footer-copy">
                {selectedLanguage === 'en' ? '© Eren Baki. All rights reserved.'
                    : selectedLanguage === 'tr' ? '© Eren Baki. Tüm hakları saklıdır'
                        : null}
            </p>
        </footer>
    );
};

export default Footer;
