import React from 'react';
import './Footer.css'


const ContactButtton = ({ selectedLanguage,navigate }) => {

    
    return (
        <div className="footer-container grid">
            <h1 className="footer-title">
                {selectedLanguage === 'en' ? 'Get In Touch Today'
                    : selectedLanguage === 'tr' ? 'Bugün İletişime Geçin'
                        : null
                }
            </h1>
            <p className="footer-paragraph container">
                {selectedLanguage === 'en' ? 'We are one of the largest truck transportation providers in the region, offering reliable and efficient logistics solutions tailored to your needs. With a strong commitment to timely deliveries and exceptional service, we are here to support your business every step of the way. Contact us today to learn how we can help streamline your transportation requirements.'
                    : selectedLanguage === 'tr' ? 'Bölgedeki en büyük kamyon taşımacılığı sağlayıcılarından biriyiz ve ihtiyaçlarınıza göre uyarlanmış güvenilir ve etkili lojistik çözümleri sunuyoruz. Zamanında teslimat ve olağanüstü hizmete olan güçlü bağlılığımızla, işinizin her adımında size destek olmak için buradayız. Taşımacılık gereksinimlerinizi nasıl kolaylaştırabileceğimizi öğrenmek için bugün bizimle iletişime geçin.'
                        : null
                }
            </p>

            <button onClick={() => navigate('/contact')} className="footer-contact-button button">
                {selectedLanguage === 'en' ? 'Contact Us'
                    : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                        : null
                }
            </button>
        </div>
    );
};

export default ContactButtton;
