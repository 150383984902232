import React from 'react';
import './Services.css';
import img_1 from '../../assets/Srvices/img (10).jpg'
import img_2 from '../../assets/Srvices/img (2).jpg'
import {FaMinus} from "react-icons/fa";

const Services = ({selectedLanguage}) => {
    return (
        <main className='main'>

            <section className='section'>
                <div className='service-container container'>
                    
                    <h2 className='section-title'>
                        {selectedLanguage === 'en' ? 'Our Services'
                            : selectedLanguage === 'tr' ? 'Hizmetlerimiz'
                                : null
                        }
                    </h2>
                    
                    <div className='service-content'>
                        <div className='service-content-info'>
                            <h2 className='section-subtitle'>
                                <FaMinus className='section-subtitle-icon'/>
                                {selectedLanguage === 'en' ? 'Our Transportation Department'
                                    : selectedLanguage === 'tr' ? 'Taşimacilik Departmanimiz'
                                        : null
                                }
                            </h2>

                            <ul className='service-content-list'>
                                <li>
                                {selectedLanguage === 'en' ? 'Our company, which has received all the qualification documents required for transportation services in national and international conditions from the relevant ministries and institutions, provides transportation services to Asian and Balkan countries.'
                                        : selectedLanguage === 'tr' ? 'Ulusal ve uluslararası koşullarda taşımacılık hizmetleri için gerekli olan tüm yeterlilik belgelerini ilgili bakanlık ve kurumlardan almış olan şirketimiz , Asya ve Balkan ülkelerine  taşımacılık hizmeti gerçekleştirmektedir.'
                                            : null
                                    }
                                </li>

                                <li>
                                    {selectedLanguage === 'en' ? 'The vehicles we will offer to your service are equipped to meet the demands of importer and exporter companies and are in a position to provide timely and healthy service.'
                                        : selectedLanguage === 'tr' ? 'Hizmetinize sunacağımız araçlarımız ithalatçı ve ihracatçı firmaların taleplerine karşılık  verebilecek donanıma sahip olup , vaktinde ve sağlıklı hizmet verebilecek durumdadır.'
                                            : null
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className='service-content-image'>
                            <img src={img_1} alt="Tax-Free Benefits for Individuals"
                                 className='service-content-img'/>
                        </div>
                    </div>

                    <div className='service-content'>
                        <div className='service-content-image'>
                            <img src={img_2} alt="Tax-Free Benefits for Individuals"
                                 className='service-content-img'/>
                        </div>

                        <div className='service-content-info'>
                            <h2 className='section-subtitle'>
                                <FaMinus className='section-subtitle-icon'/>
                                {selectedLanguage === 'en' ? 'Our Import - Export Department'
                                    : selectedLanguage === 'tr' ? 'İthalat - İhracat Departmanimiz'
                                        : selectedLanguage === 'fa' ? 'بخش واردات - صادرات ما'
                                            : null
                                }
                            </h2>

                            <ul className='service-content-list'>
                                <li>
                                    {selectedLanguage === 'en' ? 'We deliver the goods we import or export to you reliably and at extremely affordable costs by making regular departures from abroad or abroad.'
                                        : selectedLanguage === 'tr' ? 'Yurt dışından yada yurt dışına , düzenli çıkışlar yaparak, ithal yada ihraç ettiğimiz emtiayı; güvenilir ve son derece uygun maliyetlerle sizlere ulaştırmaktayız.'
                                            : selectedLanguage === 'fa' ? 'ما کالاهایی را که وارد یا صادر می‌کنیم به‌صورت منظم و با هزینه‌های بسیار مقرون‌به‌صرفه به شما تحویل می‌دهیم.'
                                                : null
                                    }
                                </li>

                                <li>
                                    {selectedLanguage === 'en' ? 'All documents related to export of the importer company are prepared by us, in order to start the transactions, the export information that the exporter company will only fill out and send to us is sufficient.'
                                        : selectedLanguage === 'tr' ? 'İthalatçı firmanın ihracatla ilgili tüm belgeler  tarafımızca  hazırlanır, işlemlere başlayabilmek   için ihracatçı firmanın tarafımıza sadece doldurarak göndereceği ihracat bilgileri yeterlidir.'
                                            : selectedLanguage === 'fa' ? 'تمام مدارک مرتبط با صادرات شرکت واردکننده توسط ما تهیه می‌شود. برای شروع معاملات، تنها اطلاعات صادراتی که شرکت صادرکننده پر کرده و به ما ارسال می‌کند کافی است.'
                                                : null
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Services;
