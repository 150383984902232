import React, { useState } from 'react';
import { IoLocationOutline } from "react-icons/io5";
import './Contact.css';
import { RiPhoneLine, RiMailLine } from 'react-icons/ri';
import emailjs from 'emailjs-com';

const Contact = ({selectedLanguage}) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_250yq9x',    // Replace with your Service ID
            'template_jzjj8gj',   // Replace with your Template ID
            {
                name: formData.name,
                email: formData.email,
                message: formData.message,
            },
            'cXxKdvT2rhHDhyIav'     // Replace with your Public Key
        ).then(
            (result) => {
                console.log('Success:', result.text);

                // Set success message based on selected language
                const successMessage = selectedLanguage === 'en'
                    ? 'Message sent successfully!'
                    : selectedLanguage === 'tr'
                        ? 'Mesaj başarıyla gönderildi!'
                        : '';

                setSuccessMessage(successMessage);
                setErrorMessage('');
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                });
            },
            (error) => {
                console.log('Error:', error.text);

                // Set error message based on selected language
                const errorMessage = 
                    selectedLanguage === 'en' ? 'Failed to send message, please try again.'
                    : selectedLanguage === 'tr' ? 'Mesaj gönderilemedi, lütfen tekrar deneyin.'
                        : null
                ;

                setErrorMessage(errorMessage);
                setSuccessMessage('');
            }
        );
    };


    return (
        <main className='main'>
            <section className="contact-section section" id="contact-us">
                <h1 className="section-title">
                    {selectedLanguage === 'en' ? 'Contact Us'
                        : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                            : ''}
                    <span>
                    {selectedLanguage === 'en' ? 'Get in touch'
                        : selectedLanguage === 'tr' ? 'İletişime geçin'
                            : ''}
                </span>
                </h1>

                <div className='contact-container container' dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                    <form className='booking-form' onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder={selectedLanguage === 'en' ? 'Your Name'
                                : selectedLanguage === 'tr' ? 'Adınız'
                                    : ''}
                            className="booking-form-input"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder={selectedLanguage === 'en' ? 'Your Email'
                                : selectedLanguage === 'tr' ? 'E-posta adresiniz'
                                    : ''}
                            className="booking-form-input"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            name="message"
                            placeholder={selectedLanguage === 'en' ? 'Your Message'
                                : selectedLanguage === 'tr' ? 'Mesajınız'
                                    : ''}
                            className="booking-form-input"
                            value={formData.message}
                            onChange={handleChange}
                            cols="30"
                            rows="6"
                            required
                        ></textarea>
                        <input type="submit"
                               value={selectedLanguage === 'en' ? 'Send Message'
                                   : selectedLanguage === 'tr' ? 'Mesaj Gönder'
                                       : ''}
                               className="button"/>
                        <br/>
                        {/* Success/Failure Message */}
                        {successMessage && <p className="success-message">{successMessage}</p>}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </form>

                    

                    <div className="contact-box">
                        <h2 className="section__title">
                            {selectedLanguage === 'en' ? 'Get in touch with us today using the provided contact details'
                                : selectedLanguage === 'tr' ? 'Bugün bizimle iletişime geçin, sağlanan iletişim bilgilerini kullanarak'
                                    : null}
                        </h2>

                        <div className="contact-data">
                            <div className="contact__information">
                                <span className="contact__description">
                                    <IoLocationOutline  className="contact__icon"/>
                                    Nusaybin Sanayi Sitesi E Blok Nusaybin/ Mardin
                                </span>
                            </div>

                            <div className="contact__information">
                                <span className="contact__description">
                                    <RiPhoneLine className="contact__icon"/>
                                    +(90) 0543 486 24 74
                                </span>
                            </div>

                            <div className="contact__information">
                                <span className="contact__description">
                                    <RiMailLine className="contact__icon"/>
                                    bakierennakliyat4773@hotmail.com
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </main>
    );
};

export default Contact;
