import React, { useEffect, useState } from "react";
import './ScrollUp.css';
import { IoIosArrowUp } from "react-icons/io";

function ScrollUp() {
    const [showScrollUp, setShowScrollUp] = useState(false);

    const handleScrollUp = () => {
        if (window.scrollY >= 350) {
            setShowScrollUp(true);
        } else {
            setShowScrollUp(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollUp);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScrollUp);
        };
    }, []);

    return (
        <button
            className={`scroll_up ${showScrollUp ? 'show' : ''}`}
            onClick={scrollToTop}
            aria-label="Scroll to top"
            title="Scroll to top"
        >
            <IoIosArrowUp />
        </button>
    );
}

export default ScrollUp;
