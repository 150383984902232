import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import './Main.css';

// COMPONENTS
import Header from './Components/Header/Header';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Home from './Components/Home/Home';
import ScrollToTop from './ScrollToTop';
import ScrollUp from './Components/ScrollUp/ScrollUp';
import Location from './Components/Location/Location';
import Services from './Components/Services/Services';
import HomeFigure from './Components/Home Figure/HomeFigure';
import Footer from './Components/Footer/Footer';
import ContactButtton from './Components/Footer/ContactButtton';

const App = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    // Function to detect the user's device language
    const detectLanguage = () => {
        const userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes('fa') || userLang.includes('ir')) {
            return 'fa';
        } else if (userLang.includes('tr')) {
            return 'tr';
        } else {
            return 'en';
        }
    };

    useEffect(() => {
        // Set the language based on the device's language
        const initialLanguage = detectLanguage();
        setSelectedLanguage(initialLanguage);
    }, []);

    // Language change handler
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // navigator ----
    const navigate = useNavigate();
    const location = useLocation(); // Access the current route

    return (
        <div>
            <ScrollToTop />
            <ScrollUp />

            <Header
                handleLanguageChange={handleLanguageChange}
                selectedLanguage={selectedLanguage}
            />

            <Routes>
                <Route
                    path="/" exact element={
                    <>
                        <Home
                            selectedLanguage={selectedLanguage}
                            navigate={navigate}
                        />
                        <HomeFigure
                            selectedLanguage={selectedLanguage}
                        />
                    </>
                }
                />
                <Route
                    path="/about" exact element={
                    <About
                        selectedLanguage={selectedLanguage}
                        navigate={navigate}
                    />
                }
                />
                <Route
                    path="/services" exact element={
                    <Services
                        selectedLanguage={selectedLanguage}
                        navigate={navigate}
                    />
                }
                />
                <Route
                    path="/location" exact element={
                    <Location
                        selectedLanguage={selectedLanguage}
                        navigate={navigate}
                    />
                }
                />
                <Route
                    path="/contact" exact element={
                    <Contact
                        selectedLanguage={selectedLanguage}
                    />
                }
                />
            </Routes>

            {/* Conditionally render ContactButton if the current path is not '/contact' */}
            {location.pathname !== '/contact' && (
                <ContactButtton
                    selectedLanguage={selectedLanguage}
                    navigate={navigate}
                />
            )}

            <Footer
                selectedLanguage={selectedLanguage}
                navigate={navigate}
            />
        </div>
    );
};

export default App;
