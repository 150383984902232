import React, { useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import './Home.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import homeImg1 from '../../assets/home/home-img-02.png';
import homeImg2 from '../../assets/home/home-img-03.png';
import homeImg3 from '../../assets/home/home-img-04.png';
import homeImg4 from '../../assets/home/home-img-01.png';

const Home = ({ selectedLanguage ,navigate}) => {
    const [mainImage, setMainImage] = useState(homeImg1);

    const handleImageClick = (src) => {
        setMainImage(src);
    };

    return (
        <main className='main' >
            <section className="home-section section" id="home">
                <div className="home__container container grid">
                    <div className="home-slider-container">
                        <div className="home-image-container">
                            <img src={mainImage} alt="Delicious dish" className="home-img" />
                        </div>

                        <Swiper
                            className='home-swiper-container'
                            modules={[Autoplay, Pagination, Navigation]}
                            spaceBetween={20}
                            loop={true}
                            grabCursor={true}
                            autoplay={{ delay: 7500, disableOnInteraction: false }}
                            breakpoints={{
                                0: { slidesPerView: 2 },
                            }}
                            navigation={true}
                        >
                            <SwiperSlide className='home-swiper-slide'>
                                <img src={homeImg1} alt="Slide 1" className='home-slide-img' onClick={() => handleImageClick(homeImg1)} />
                            </SwiperSlide>
                            <SwiperSlide className='home-swiper-slide'>
                                <img src={homeImg2} alt="Slide 2" className='home-slide-img' onClick={() => handleImageClick(homeImg2)} />
                            </SwiperSlide>
                            <SwiperSlide className='home-swiper-slide'>
                                <img src={homeImg3} alt="Slide 3" className='home-slide-img' onClick={() => handleImageClick(homeImg3)} />
                            </SwiperSlide>
                            <SwiperSlide className='home-swiper-slide'>
                                <img src={homeImg4} alt="Slide 4" className='home-slide-img' onClick={() => handleImageClick(homeImg4)} />
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className="home__data" dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                        <h1 className="home__title">
                            {selectedLanguage === 'en' ? 'Transportation Department'
                                : selectedLanguage === 'tr' ? 'Ulaştırma Departmanı'
                                        : null
                            }
                        </h1>
                        <p className="home__description">
                            {selectedLanguage === 'en' ? 'Efficient and reliable services for all your import and export needs'
                                : selectedLanguage === 'tr' ? 'Tüm ithalat ve ihracat ihtiyaçlarınız için etkili ve güvenilir hizmetler'
                                        : null
                            }
                        </p>
                        <button onClick={() => navigate('/contact')} className="button">
                            {selectedLanguage === 'en' ? 'Contact Us'
                                : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                                        : null
                            }
                            <AiOutlineArrowUp className="button__icon" />
                        </button>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Home;
